/*For xs - sm size*/
@media only screen and (max-width: 959px) {
  #mobile-app-title {
    text-align: center;
  }
  #mobile-app-header {
    margin-bottom: 0;
  }
  .mg-up30{
    margin-top: 0px;
  }
  div.adorned-start  {
    padding-left: 0px;
  }
  .apply-loan-buttons {
    margin-top: 40px;
  }
  button.button-login{
    width: 100% !important;
  }
  button.button-register{
    width: 100% !important;
    margin-left: 0 !important;
    margin-top: 14px;
  }
  .about-2-desc {
    text-align: justify;
    padding-right: 0;
  }
  .navbar-fixed .img-logo {
    width: 150px;
  }
  .navbar.navbar-fixed button {
    font-size: 9px;
  }
  #link-logo {
    margin-top: 7px;
  }
  .navbar-fixed #link-logo {
    margin-top: 4px;
  }
  .popular-posts {
    margin: 15px 0 0 0;
  }
  .hidden-xs {
    display: none !important;
  }
  .nav-home {
    height: auto;
  }
  .home3-content-container{
    padding-left:0;
  }
  .home3-content {
    margin: 20px 0px;
  }
  .how1-grid {
    margin-top: 40px;
  }
  .how1-image {
    margin-top: 25px;
  }
  .how-2 {
    padding-top: 30px;
  }
  .how3-image {
    margin: 10px auto 22px;
    max-width: 315px;
  }
  .prop-invest {
    /* margin-left: 0;
    margin-top: 30px; */
  }
  .prop-detail-stat, .prop-invest {
    margin-top: 5px;
  }
  .info-offer{
    width: 80%;
  }
  .info-offer{
    width:100%;
  }
  .db-portfolio div.react-sweet-progress {
      margin-bottom: 8px;
  }
  .artboard-4-bottom{
    background-size: contain;
  }
  .artboard-5-bottom{
    background-size: contain;
  }
  .home1-container .slick-list {
    max-height: 650px;
  }
}

/*For xs - md size*/
@media only screen and (max-width: 1279px) {
  .card {
    margin-bottom: 10px;
  }
  .info-offer{
    width: 90%;
  }
}

/*For xs - lg size*/
@media only screen and (max-width: 1919px) {

}

/*For xl size*/
@media only screen and (min-width: 1920px) {

}


/*For sm - xl size*/
@media only screen and (min-width: 600px) {
}
/*For md - xl size*/
@media only screen and (min-width:960px) {
  #dashboard-buttons-area button {
    /* color: #6b7c93; */
    color: rgb(107, 124, 147, 0.88);
    width: 153px;
    text-transform: none;
    padding-top: 17px;
    padding-bottom: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.5);
    font-weight: normal;
    background-color: white;
  }
  #dashboard-buttons-area button:hover{
    background-color: #e6e6e6;
  }

  #dashboard-buttons-area button {
    text-decoration: none;
    margin-bottom: 20px;
    text-decoration: none;
  }

  #dashboard-buttons-area button.active {
    color: white;
    background-color: #3d7dc8;
    text-decoration: none;
  }
  .prop-detail-stat, .prop-invest {
    margin-left: 10px;
  }

  .htl-container {
    /* suppose to be 4.17%, but push right a bit  */
    padding-left: 4.5%;
  }

}

/* lg & xl */
@media (min-width:1280px) {
  .container {
    width: 1140px !important;
  }

  #dashboard-buttons-area {
    margin-right: 60px;
  }
  button.button-login-detail{
    margin-top: 10px !important;
    width: 15% !important;
  }
  button.button-register-detail{
    margin-top: 10px !important;
    margin-left: 10px !important;
    width: 15% !important;
  }
  div.login-detail-content{
    width: 65% !important;
    float: left;
    padding-right: 20px;
    padding-bottom: 20px;
  }
}

/* md only */
@media (min-width:960px) and (max-width: 1279px) {
  .container {
    width: 900px !important;
  }
  .advisor-card p {
    margin: 0;
  }
  div.wallet-balance {
    margin-bottom: 10px;
  }
  .advisor-title {
    line-height: 1.5;
  }
  .advisor-name {
    line-height: 1.3;
  }
  p.advisor-title {
    margin-bottom: 8px;
  }
  .advisor-space-2 {
    margin-bottom: 14px;
  }
  .about-section {
    /* height: 2309px; */
    height: 2282px;
  }
  .home2-card {
    width: auto;
    padding: 53px 30px 0px;
    min-height: 262px;
  }
  .dash-area {
    padding: 29px 38px;
  }
  .dash-area.portfolio-list {
    padding: 29px 20px;
  }
  .db-box {
    padding: 14px 15px 16px;
  }
  #dashboard-buttons-area {
    margin-right: 50px;
  }
  .team-image {
    height: 161px;
  }
  .db-main-box .db-subbox {
    text-align: center;
    padding: 14px 4px 14px;
    min-height: 106px;
  }
  .db-info-box .db-subbox {
    padding: 14px 12px 14px;
  }
  .nav-home {
    /* height: 559px; */
  }
  button.button-navbar{
    margin-right: 10px;
  }
  .timeline-box {
    height: 239px;
  }
  .products-choices{
    width: 100%;
  }
  .sub-navbar{
    width: 90%;
    margin: 0 auto;
  }
  .sub-navbar-change{
    width: 90%;
    margin: 0 auto;
  }
  .footer-content{
    width: 80%;
    text-align: justify;
  }
  .need-fund-content{
    width: 90%;
    text-align: center
  }
  .proplist-container{
    /* margin-left: 2%; */
  }
  .card {
    width: 100%;
  }
  .product-container{
    width: 100%;
  }
  .about-us{
    width: 100%;
    text-align: justify !important;
  }
  .how-width{
    width: 96%;
    margin: 0 auto;
  }
  .how-page{
    width: 90%;
    margin: 0 auto;
    text-align: justify;
  }
  .how-image{
    width: 100%;
    margin: 0 auto;
  }
  .home1-image{
    width: 90%;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .home1-grid {
    padding-left: 3%;
  }
  .home1-button {
    margin-top: 28px;
  }
  .home-2{
    width: 100%;
  }
  .prop-detail-carou, .prop-detail-stat {
    height: 360px;
  }
  .prop-detail-progress {
    /* width: 270px; */
  }
  .info-offer.prop-detail-type {
    padding: 15px 5px 11px 18px;
  }
  .image-gallery-content {
    width: 100%;
  }
  .apply-loan-buttons {
    margin-top: 75px;
  }
  .paper-body {
    padding: 16px 36px 20px;
  }
  .paper-body-inverse {
    margin: 0 -36px;
  }
  .home3-content {
    margin: 7px -15px auto;
  }
  .loan1-buttons {
    margin-top: 13px;
  }
  .loan1-image {
    width: 83%;
  }
  button.button-login-detail{
    margin-top: 20px !important;
    width: 20% !important;
  }
  button.button-register-detail{
    margin-top: 20px !important;
    margin-left: 20px !important;
    width: 20% !important;
  }
}

/* sm only */
@media (min-width:600px) and (max-width: 959px) {
  #dashboard-buttons-area button {
    /* color: #6b7c93; */
    color: rgb(107, 124, 147, 0.88);
    width: 153px;
    text-transform: none;
    padding-top: 17px;
    padding-bottom: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.5);
    font-weight: normal;
    background-color: white;
  }
  #dashboard-buttons-area button:hover{
    background-color: #e6e6e6;
  }

  #dashboard-buttons-area button {
    text-decoration: none;
    margin-bottom: 20px;
    text-decoration: none;
  }

  #dashboard-buttons-area button.active {
    color: white;
    background-color: #3d7dc8;
    text-decoration: none;
  }
  .container{
    width: 580px !important;
  }
  .home1 {
    margin-bottom: 60px;
  }
  .home2-card {
    margin: 23px auto;
  }
  .divider-advisor {
    margin-top: 0;
  }
  .center-sm {
    text-align: center;
  }
  .loan1-image {
    width: 75%;
    margin: 39px auto 0;
  }
  #dashboard-buttons-area {
    margin: 0;
  }
  #dashboard-buttons-area button {
    width: 144px;
  }
  #dashboard-buttons-area a {
    text-decoration: none;
    margin-bottom: 5px;
  }
  .timeline-box {
    height: auto;
  }
  .cred-area {
    padding: 40px 20px 20px;
  }
  .dash-area {
    padding: 25px 27px;
  }

  .dbsidebarplace{
    width: 580px !important;
  }

  .need-fund-content{
    padding-bottom: 50px;
  }
  .socmed-bottom{
    margin-left: 17%;
  }
  .ojk-card{
    width: 90%;
    text-align: center;
  }
  .ojk-title .ojk-content{
    width: 100%;
    text-align: center;
  }
  .card{
    width: 100%;
  }
  .card-content-div{
    width: 250px;
  }
  .ojk-image{
    width: 90%;
  }
  .home3{
    height: 100%;
  }
  button.button-navbar{
    margin-left: 15px;
  }
  .prop-detail-carou, .prop-detail-stat {
    height: auto;
  }
  .prop-detail-progress {
    /* width: 90%; */
  }
  .info-offer.prop-detail-type {
    padding: 15px 33px 11px 53px;
  }
  .image-gallery-content {
    width: 100%;
  }
  .sub-navbar {
    height: 88px;
  }
  .home1{
    /* height: 250px; */
  }
  .home3-bg{
    background: none;
  }
  .home3-title{
    padding-top: 50px;
  }
  img.proses-bangunan{
    display: none;
  }
  .plus-div{
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .demokratisasi{
    text-align: center;
    padding-top: 30px;
  }
  .about-section{
    /* height: 3996px; */
    height: 3425px;
  }
  .advisor-space-2{
    margin-top: 20px;
  }
  .about-card-bg{
    margin-top: 80px;
  }
  .about-card-except{
    margin-top: 80px;
  }
  .about-card{
    margin-top: 0px;
  }
  .team-image{
    padding-bottom: 0px;
  }
  .for-flipper{
    padding-top: 15px;
    padding-bottom: 20px;
  }
  .our-partner{
    padding-top: 175px;
  }
  .call-us{
    padding-top: 30px;
  }
  .home-2 {
    position: relative;
    height: auto;
    margin-top: 20px;
  }
  .home2-padding {
    height: 30px;
  }
  .home-2-float {
    position: relative;
    top: 0;
  }
  button.button-login-detail{
    margin-top: 20px !important;
    width: 45% !important;
  }
  button.button-register-detail{
    margin-top: 20px !important;
    margin-left: 10px !important;
    width: 45% !important;
  }
  .db-main-box .db-subbox {
    text-align: center;
  }
  .modal-home-content {
    background-color: #0075DF;
    background-image: none;
    background-size: 550px 100%;
    background-repeat:repeat-x;
    min-width: 300px;
    height:100%;
    padding: 15px;
  }
  .modal-prohouse-content {
    background-color: #0075DF;
    background-image: none;
    background-size: 550px 100%;
    background-repeat:repeat-x;
    min-width: 300px;
    height:100%;
    padding: 15px;
  }
}



/* xs only */
@media (max-width:599px) {
  .container {
    width: 92%;
  }
  .dash-area {
    padding: 25px 15px;
  }
  .dbsidebarplace{
    width: 100% !important;
  }
  .box-wallet {
    text-align: center;
  }
  .timeline-box {
    height: auto;
    max-height: 250px;
  }
  .cred-area {
    padding: 30px 15px 12px;
  }
  .db-main-box .db-subbox {
    text-align: center;
  }
  div.stepper-label {
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .stepper-content {
    padding: 10px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .cred-area .stepper-content {
    padding: 0;
  }
  .ojk-left{
    text-align: center;
  }
  .card{
    width: 340px;
    max-width: 100%;
  }
  .right-fund {
    margin-top: 10px;
    margin-bottom: 31px;
  }
  .table-transact th, .table-transact td {
    padding: 7px 6px;
  }
  .right-fund button {
    width: 245px;
    max-width: 100%;
  }
  button.button-navbar{
    margin-left: 15px;
  }
  .mobile-justify{
    text-align: justify !important;
  }
  .mobile-center{
    text-align: center !important;
  }
  .faq-q {
    margin: 10px 0;
  }
  .faq-q>p{
    text-align: left !important;
  }
  .career1-left{
    margin-top: 0;
  }
  button.button-socmed-login{
    width: 100%;
  }
  .sms-modal-content {
    padding: 10px 6px;
  }
  .prop-detail-carou, .prop-detail-stat {
    height: auto;
  }
  .prop-detail-progress {
    /* width: 90%; */
  }
  .info-offer.prop-detail-type {
    padding: 10px 17px;
  }
  .image-gallery-content {
    width: 100%;
  }
  #form-invest {
    text-align: left;
  }
  .container.prop-detail-page {
    margin-top: 15px;
  }
  .bottom-navigation {
    position: fixed;
    bottom: 0;
    box-shadow: 0 2px 36px -11px #111;
    z-index: 1000;
    width: 100%;
    overflow-x: scroll;
  }
  .home3-container{
    margin-top: 0px !important;
  }
  .slick-next {
    right: 38% !important;
    margin-top: 130px !important;
  }
  .slick-prev {
    left: 38% !important;
    margin-top: 130px !important;
  }
  .home-card {
    opacity: 1;
  }
  button.button-navbar{
    color: white !important;
    margin-left: 25px;
  }
  button.loan1-button{
    width: 100%;
  }
  .statistic-number {
    font-size: 7vw;
  }
  .stat-statement {
    margin-top: 22px;
  }
  .sub-navbar {
    height: 80px;
  }
  .file-peek {
    width: 90px;
  }
  .action-hook {
    margin-top: inherit !important;
  }
  .testimoni-card{
    width: 280px;
    margin: 0 auto;
    opacity: 1;
    pading: 15px;
  }
  .slick-slide.slick-center .testimoni-card {
    transform: scale(1) !important;
    opacity: 1;
    filter: none;
  }
  .btn-dark-blue{
    margin: 0 auto;
    padding: 9px;
  }
  button.button-mulai-investasi{
    width: 100% !important;
    margin-top: 10px;
  }
  button.btn-dark-blue{
    width: 100% !important;
    margin-top: 14px;
  }
  .home1 {
    padding-top: 0% !important;
    height: auto;
  }
  .home3-title{
    padding-top: 50px !important;
  }
  .img-home-3{
    width: 170px;
    margin: 0 auto;
  }
  .home3-bg{
    background: none !important;
  }
  button.cara-pinjaman{
    width: 100%;
  }
  .skema-title{
    text-align: center;
    margin: 0 auto;
  }
  .skema-content{
    text-align: center;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .footer-content{
    text-align: left !important;
  }
  .proses-bangunan{
    display: none;
  }
  .how2-content{
    padding-bottom: 20px;
  }
  .loan6-title{
    margin: 0 auto;
    text-align: center;
  }
  .flipping-title{
    text-align: center;
  }
  .masih-ada-pertanyaan{
    text-align: center;
  }
  .how-to-invest{
    text-align: center;
  }
  .invest-content{
    text-align: center;
    margin-top: 20px;
  }
  div.footer-left{
    text-align: justify !important;
  }
  .flipper-title{
    text-align: center;
  }
  .home1-left{
    text-align: center;
  }
  .how1-left{
    text-align: center;
  }
  .plus-div{
    margin-top: -30px;
    padding-bottom: 30px;
  }
  .loan-2{
    padding-top: 100px;
    padding-bottom: 20px;
  }
  .demokratisasi{
    text-align: center;
    padding-top: 30px;
  }
  .about-section {
    height: auto;
  }
  .advisor-space-2{
    margin-top: 20px;
  }
  .about-card-bg{
    margin-top: 80px;
  }
  .about-card-except{
    margin-top: -50px;
  }
  .team-image{
    padding-bottom: 0px;
  }
  .for-flipper{
    padding-top: 15px;
    padding-bottom: 20px;
  }
  .home3{
    min-height: 100px !important
  }
  .img-card-home{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .testimoni{
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .our-partner{
    padding-top: 60px;
  }
  .call-us{
    padding-top: 30px;
  }
  .team-card{
    opacity: 1;
    margin: 0 auto;
  }
  .paper-body {
    padding: 16px 14px 20px;
  }
  .paper-body-inverse {
    margin: 0 -14px;
  }
  .paper-value {
    font-size: 15px;
  }
  .payment-bank {
    padding: 12px 13px;
  }
  #listing-bar {
    padding: 10px;
  }
  .home1-content {
    text-align: center;
  }
  .home1-buttons button {
    padding: 9px 0;
  }
  .how1-buttons button {
    padding: 9px 0;
  }
  .home-2 {
    position: relative;
    height: auto;
    margin-top: 20px;
  }
  .home2-padding {
    height: 20px;
  }
  .home-2-float {
    position: relative;
    top: 0;
  }
  .home2-card {
    width: auto;
    min-height: auto;
    padding: 15px 13px 21px;
    text-align: left;
    margin: 11px 0;
  }
  .home2-card img {
    display: block;
  }
  .home-2 {
    width: 100%;
    overflow: hidden;
  }

  .home-card {
    transform: scale(1);
    transition: all .15s ease;
  }
  .slick-slide.slick-active .home-card {
    transition: all .15s ease;
  }
  .slick-slide.slick-active.slick-current .home-card {
    transform: scale(1.03);
    transition: all .15s ease;
  }
  .how3 {
    padding: 50px 0;
  }
  .loan1-image {
    width: 91%;
    margin: 17px auto 0;
  }
  .loan1-grid {
    margin-top: 38px;
  }
  .loan1-buttons {
    margin-top: 15px;
  }
  .loan1-buttons button {
    padding: 9px 0;
  }
  .portfolio-list .radio-root {
    margin-bottom: -10px;
  }
  p.p-btn-modal {
    margin: 0;
  }
  .prop-detail-tab {
    padding: 30px 4%;
  }
  .propinvest-detail {
    padding: 30px 5%;
  }
  .propinvest-detail-inverse {
    margin: 0 -5%;
  }
  div.wallet-balance {
    margin-bottom: 15px;
  }
  .info-offer-detail{
    width: 312px;
    height: 38px !important;
  }
  button.button-lihat-proyek{
    width: 100%;
    margin-top: 0px;
  }
  .testimoni{
    margin-top: 40px;
  }
  .ribbon-placeholder{
    margin-top: 13px !important;
  }
  button.button-login-detail{
    margin-top: 20px !important;
    width: 48% !important;
  }
  button.button-register-detail{
    margin-top: 20px !important;
    margin-left: 10px !important;
    width: 48% !important;
  }
  .view-email-paper {
    margin: 10px !important;
  }
  .modal-home-content {
    background-color: #0075DF;
    background-image: none;
    background-size: 550px 100%;
    background-repeat:repeat-x;
    min-width: 300px;
    height:100%;
    padding: 15px;
  }
  .modal-prohouse-content {
    background-color: #0075DF;
    background-image: none;
    background-size: 550px 100%;
    background-repeat:repeat-x;
    min-width: 300px;
    height:100%;
    padding: 15px;
  }
  .home1-container .slick-list {
    max-height: 900px;
  }
  .loan-step1{
    background-image: url("./assets/loan/step1mobile.png");
    background-size: 135px 150px;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    height: 150px;
  }
  .loan-step2{
    background-image: url("./assets/loan/step2mobile.png");
    background-size: 135px 150px;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    height: 150px;
  }
  .loan-step3{
    background-image: url("./assets/loan/step3mobile.png");
    background-size: 135px 150px;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    height: 150px;
  }
  .loan-step4{
    background-image: url("./assets/loan/step4mobile.png");
    background-size: 135px 150px;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    height: 150px;
  }
  .loan-step5{
    background-image: url("./assets/loan/step5mobile.png");
    background-size: 135px 150px;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    height: 150px;
  }
  .loan-step6{
    background-image: url("./assets/loan/step6mobile.png");
    background-size: 135px 150px;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    height: 150px;
  }
  .auto-field-1 {
    width: auto;
  }
}

/* special case */
@media (min-width:342px) and (max-width: 409px) {
  .home1-title {
    margin: 0 40px;
  }
  .info-offer-detail{
    width: 91.8%;
    height: 38px;
  }
}

@media (max-width:320px){
  .info-offer-detail{
    width: 90%;
    height: 38px;
  }
  .modal-home-content {
    background-color: #0075DF;
    background-image: none;
    background-size: 550px 100%;
    background-repeat:repeat-x;
    min-width: 200px;
    height:100%;
    padding: 15px;
  }
  .modal-home-content {
    background-color: #0075DF;
    background-image: none;
    background-size: 550px 100%;
    background-repeat:repeat-x;
    min-width: 200px;
    height:100%;
    padding: 15px;
  }
  .artboard-button-width{
    width: 300px !important;

  }
}
