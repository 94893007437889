@import url('icon.css');
@import url('swiper.css');

body {
  display: block;
  padding: 0;
  margin: 0 auto;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  vertical-align: baseline;
  color: #6b7c93;
}

body .myTheme, .popselect li {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  vertical-align: baseline;
  color: #6b7c93;
}
.color-theme {
  color: #6b7c93 !important;
}

button[type="button"], button[type="submit"], label[role="button"] {
  font-family: 'Montserrat', sans-serif;
  text-transform: none;
  box-shadow: none;
  font-weight: 400;
}
#dashboard-buttons-area button {
  font-size: 14px;
}

button.button-how-loan {
  color: #3d7dc8;
  background-color: white;
  border: 1px solid #3d7dc8;
  width: 100%;
}

button.button-blog-prevnext {
  font-weight: bold;
  color: #3d7dc8;
}


/* reset property detail description */
h1 { font-size: 36px; }
h2 { font-size: 30px; }
h3 { font-size: 24px; }
h4 { font-size: 18px; }
h5 { font-size: 14px; }
h6 { font-size: 12px; }
h4, h5, h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.table-bordered {
  border: 1px solid #ddd;
}
.table-bordered > tbody > tr > td {
  border: 1px solid #ddd;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}
.table-container {
  width: 100%;
  overflow-y: auto;
  margin: 0 0 1em;
}
.table-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
}
.table-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid #fff;
  background-color: rgba(0, 0, 0, .3);
}
.prop-detail .html-content {
  width: 100%;
}
.prop-detail img, .prop-detail iframe {
  max-width: 100%;
}

.table-title {
  font-family: 'Montserrat', sans-serif;
  color: #6b7c93;
  font-size: 14px;
  font-weight: bold;
}

.menu-other a, .important-links a, .db-box a {
  text-decoration: none;
  color: #6b7c93;
}
.sms-modal.rodal {
  z-index: 1001;
}
.cb-root {
  margin-top: 8px;
  margin-bottom: -7px;
}

.react-code-input input[type=number]::-webkit-inner-spin-button,
.react-code-input input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.dash-title {
  font-weight: 600;
  color: #6b7c93;
  margin-left: 20px;
  margin-top: 30px;
}

.dash-content {
  color: #6b7c93;
  margin-left: 20px;
}

.table-standard tr {
  height: auto;
}

.table-standard td {
  font-family: 'Montserrat', sans-serif;
  color: #6b7c93;
  border-bottom: none;
  padding: 0;
}

p.my-form-helpertxt {
  font-family: 'Montserrat', sans-serif;
  color: #6b7c93;
  font-size: 0.7rem;
  margin: 4px 6px 0;
}

p.my-form-helpertxt.error {
  color: #f44336;
}
.mytabs {
  box-shadow: 0 0 13px 0 #f0f0f0;
  margin-bottom: 5px;
}
.mytabs .flex-container {
  background-color: white;
}

button.nav-selected, .blue-color {
  color: #3d7dc8 !important;
}

.error-text {
  font-size: 0.75rem;
  color: #f44336;
}

div.select-item {
  font-family: 'Montserrat', sans-serif;
  padding: 6px;
  font-size: 14px;
}

.container-login {
  position: relative;
}

.stepper-label span.step-label {
  font-family: 'Montserrat', sans-serif;
  color: #6b7c93;
}

div.react-sweet-progress-symbol {
  font-weight: 300;
}

.db-portfolio div.react-sweet-progress-symbol {
  font-size: 12px;
  padding-left: 5px;
  width: 17px;
}
.pf-title {
  font-size: 13px;
  font-weight: 500;
}
.mou-icon {
  color: darkgrey;
  margin: 1px 0 0 8px;
  font-size: 22px;
  position: absolute;
  top: 0;
  left: 0;
}
.mou-icon-wrapper {
  display: inline-block;
  position: relative;
  width: 26px;
  height: 15px;
}

svg.step-icon.step-icon-active {
  color: #3d7dc8;
}

svg.step-icon.step-icon-completed {
  color: #52b238;
}

.credential-page .step-label-active .step-label {
  color: #6b7c93;
}

.ReactModalPortal>div {
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  transition: opacity 200ms ease-in-out;
  background: rgba(0, 0, 0, 0.15);

  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
  }
}

.my-form-control .my-form-label, .my-form-control .my-select-placeholder {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #aaa;
}

.my-form-control .my-form-label.label-focused {
  color: #3d7dc8;
}

.my-form-control .my-form-input, .my-form-control .my-form-input-outlined, .my-form-control .my-form-select {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #6b7c93;
  text-align: left;
}

.my-form-control .input-underline:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.my-form-control .input-underline:hover:not(.input-disabled):not(.input-focused):not(.input-error):before {
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
}

#form-prohouse .my-form-input-outlined, #form-prohouse .my-form-select {
  background-color: white;
}
#form-prohouse .my-form-input-outlined > input {
  padding: 17.5px 14px;
}
.my-form-input-outlined.input-focused>fieldset {
  border-color: #3d7dc8 !important;
}

.my-form-control .input-underline:after {
  border-bottom-color: #3d7dc8;
}

.my-form-control .my-form-label.label-shrink {
  font-weight: 700;
  color: #6b7c93;
}

.my-form-control input {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}

.react-select-ic>div {
  padding: 4.5px;
  cursor: pointer;
}
#form-prohouse span.my-radio {
  padding: 1px 13px;
}
span.my-radio {
  /* padding-right: 5px; */
}
span.my-radio.radio-checked {
  color: #3d7dc8;
}
span.my-radio svg {
  font-size: 20px;
}

.navbar {
  border: none;
  background-color: transparent;
}

.content-nav {
  position: fixed;
  border: none;
  max-height: 100px;
  background-image: linear-gradient(to top, #2475d1, #1b4c91 100%, #1f5fae, #13285a);
  width: 100%;
  z-index: 1000;
  transition: max-height 0.5s ease-in;
  overflow: scroll;
  color: white !important;
}

.invisible {
  max-height: 0;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
  padding-bottom: 0px;
}

.navbar a {
  text-decoration: none;
}

.navbar.navbar-fixed {
  width: 100%;
  border: none;
  background-image: linear-gradient(to top, #2475d1, #1b4c91 100%, #1f5fae, #13285a);
  position: absolute;
  z-index: 100;
  top: 0;
  height: 64px;
  -webkit-box-shadow: 0 -6px 40px 4px #111;
  box-shadow: 0 -6px 40px 4px #111;
}
.navbar-fixed .sub-navbar {
  height: 64px;
}


/* this is to make sure dom structure not collapsing when fixed occur */
.sub-navbar-dummy {
  height: 100px;
}

.sub-navbar {
  height: 100px;
  display: -webkit-flex;
  /* Safari */
  -webkit-align-items: center;
  /* Safari 7.0+ */
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

div.soft-paper {
  border-radius: 3px;
  box-shadow: 0 6px 8px 0 rgba(164, 164, 164, 0.21);
}

.img-logo {
  width: 150px;
}

.img-error {
  border: 1px solid #f44336;
}

.navbar button {
  text-transform: none;
  color: #1d5d91;
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
}
a .MuiButton-label-134 {
  text-transform: none;
}

.menu-other a, .menu-other a li, .menu-other li {
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
  color: #6b7c93;
  text-transform: none;
  min-width: 55px;
}

.nav-home {
  background-image: linear-gradient(to top, #ffffff, #f1f8ff);
  background-size: cover;
  height: 100%;
}

button.button-login {
  background: transparent;
  outline: none;
  cursor: pointer;
  padding-right: 12px;
  padding-left: 12px;
}
.bg-blue-to-white {
  background-image: linear-gradient(to top, #ffffff, #f1f8ff);
}
.prop-detail-tab {
  padding: 30px 30px;
  background-color: white;
}
.prop-swipe-tab {
  background-color: #f3f8ff;
}

button.button-socmed-login {
  border-radius: 5px;
  width: 100%;
  margin-bottom: 12px;
}

button.button-login-home {
  border: 1px solid white;
  background-color: transparent;
}

button.button-login-plain {
  border: 1px solid white !important;
  color: white !important;
}

button.button-login-other {
  border: 1px solid #3d7dc8 !important;
  color: #3d7dc8;
}

button.button-navbar {
  margin-right: 15px;
  background: transparent;
  outline: none;
  cursor: pointer;
  border: none;
  padding: 10px;
}
.navbar a.active button.button-navbar, .navbar button.contain-active, .navbar a.active li {
  font-weight: 600;
}
.navbar a.active button.button-navbar::before, .navbar button.contain-active::before {
  content: "·";
  font-size: 25px;
  position: absolute;
  left: 1px;
  top: 4px;
}
.navbar a.active li::before {
  content: "\B7";
  font-size: 25px;
  position: absolute;
  left: 6px;
  top: 12px;
}

button.button-navbar-bottom {
  margin-right: 25px;
  margin-left: 25px;
  background: transparent;
  outline: none;
  cursor: pointer;
  border: none;
  padding: 10px;
  color: white !important;
}

button.button-register {
  outline: none;
  cursor: pointer;
  background-color: blue !important;
  color: white!important;
}

button.button-register-home {
  background-color: white !important;
  color: #3d7dc8 !important;
}

button.button-register-plain {
  background-color: white !important;
  color: #3d7dc8 !important;
}

button.button-register-other {
  background-color: #3d7dc8 !important;
  color: white !important;
}

.navbar-home {
  color: white;
}

.bottom-navbar {
  box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.5);
  background-color: white;
}

.navbar-home button {
  color: #1d5d91;
}

.navbar-fixed {
  color: white;
}

.navbar-fixed button {
  margin-top: 2px;
  color: white;
}

.button-navbar .material-icons {
  font-size: 16px;
}

.button-menu {
  background: transparent;
  outline: none;
  cursor: pointer;
  border: none;
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
  color: #13285a;
}

.button-menu-bottom {
  background: transparent;
  border: 2px solid white;
  border-radius: 3px;
  outline: none;
  color: white;
  border: none;
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
}

.navbar-fixed .button-menu {
  border: 2px solid white;
  color: white;
  margin-top: 3px;
  padding-top: 3px;
  border-radius: 5px;
  width: 50px;
}

.prop-detail button.tab-root {
  min-width: 100px;
  font-size: 15px;
  font-weight: 400;
}

button.tab-root {
  font-size: 14px;
  font-weight: 300;
}
button.tab-root.tab-selected {
  color: #3d7dc8;
  font-weight: 600;
}

.menu-position {
  text-align: right;
  margin-right: 6%;
}

.button-navbar:hover {
  border-radius: 5px;
  background-color: lightgray;
}

.navbar>.sub-navbar {
  -webkit-flex: 1;
  /* Safari 6.1+ */
  flex: 1;
}

.db-title-loan {
  font-size: 19px;
  font-weight: 600;
  line-height: 1.74;
  letter-spacing: 1.7px;
  color: #3d7dc8;
}

.p-btn-modal {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #6b7c93;
}

.db-title {
  font-size: 13px;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 7px;
}
.py-title {
  font-size: 13px;
}
.py-content {
  font-weight: bold;
}
.font12 {
  font-size: 12px !important;
}

.dash-area {
  font-size: 14px;
}

.db-highlight {
  font-weight: 600;
  font-size: 15px;
}

.db-box {
  border: 1px solid rgb(0, 0, 0, 0.13);
  border-radius: 4px;
  padding: 14px 24px 14px;
  transition: all 150ms ease-in;
}

.db-subbox {
  padding: 14px 24px 14px;
  transition: all 150ms ease-in;
}

.db-main-box .db-title {
  font-size: 16px;
  margin-bottom: 8px;
}
.db-main-box .db-subbox {
  min-height: 100px;
}
.db-main-box .db-title img {
  width: 22px;
  margin-right: 5px;
}
.db-info-box .db-title img {
  width: 18px;
  margin-right: 5px;
}
.db-main-box .db-highlight {
  font-size: 18px;
}
.db-info-box .db-subbox {
  padding: 14px 14px 14px;
}

.hover-hlight:hover {
  color: white;
  background-color: #3d7dc8;
  transition: all 150ms ease-in;
}
.hover-hlight:hover img {
  filter: brightness(0) invert(1);
}
.hover-hlight:hover .info-pop-link {
  color: white;
}

.db-box .material-icons {
  font-size: 14px;
}

.db-box .button-wallet {
  font-size: 12px;
  font-weight: 400;
  text-transform: none;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(100, 100, 100, 0.5);
  height: 34px;
  width: 100%;
  background-color: white;
  color: #6b7c93;
}
.hover-hlight .button-wallet:hover {
  background-color: #eeeeee;
}
.form-legend {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-align: left;
  margin-left: -5px;
  margin-top: 10px;
  margin-bottom: -6px;
}
.form-legend:first-of-type {
  margin-top: 0px;
}
#form-prohouse .form-legend {
  margin-top: 28px;
  margin-bottom: 8px;
}

.timeline-box {
  height: 234px;
}

.table th, .table td {
  font-family: 'Montserrat', sans-serif;
}
.table-transact td {
  padding: 8px 10px;
}

.table-timeline td {
  padding: 2px 10px;
}
.table-timeline th, .table-transact th {
  padding: 8px 10px;
  font-weight: 700;
}

.table td {
  color: #6b7c93;
}
/* .img-sponsor-compt {
width: 120px;
margin-right: 25px;
} */
.info-pop-link {
  cursor: pointer;
  color: #969ea9;
}

.nowrap {
  white-space: nowrap;
}

.tooltip-content div {
  font-family: 'Montserrat', sans-serif;
  max-width: 280px;
  background-color: #ffdc19;
  color: #4a4a4a;
  font-size: 11px;
}
div[role="tooltip"] {
  z-index: 2000;
}

.dash-area {
  padding: 35px 46px;
}

.bs-paper {
  box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.5) !important;
}

.button-blue {
  color: white !important;
  background-color: #3d7dc8 !important;
  border: 1px solid #3d7dc8 !important;
}

.button-blue:hover {
  color: white !important;
  background-color: #B2C9EF !important;
  border: 1px solid #3d7dc8 !important;
}

.button-blue:disabled {
  background-color: #c3d7ee !important;
}

.button-navy {
  color: white !important;
  background-color: #1d5d91 !important;
  border: 1px solid #1d5d91 !important;
}

.button-navy:hover {
  color: white !important;
  background-color: #B2C9EF !important;
  border: 1px solid #1d5d91 !important;
}

.button-navy:disabled {
  background-color: #c3d7ee !important;
}

.button-white {
  color: #3d7dc8 !important;
  background-color: white !important;
  border: 1px solid #3d7dc8 !important;
}
.button-white:hover {
  color: white !important;
  background-color: #ddd !important;
  border: 1px solid #B2C9EF !important;
}

.button-transparent {
  color: #3d7dc8 !important;
  background-color: none !important;
  border: 1px solid #3d7dc8 !important;
}
.button-transparent:hover {
  color: white !important;
  background-color: none !important;
  border: 1px solid #B2C9EF !important;
}

.button-red {
  color: white !important;
  background-color: #c23f4f !important;
}
.button-red:disabled {
  background-color: #edc5ca !important;
}
button.del-button {
  min-width: inherit;
}
.action-hook {
  margin-top: 9px !important;
}
.button-shadow {
  box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.5) !important;
}

button.button-fb {
  color: white !important;
  background-color: #3B5998;
  /* margin-right: 10px; */
  /* margin-bottom: 15px; */
}

button.button-fb:disabled {
  background-color: #899bc1;
}

button.button-google {
  color: white !important;
  background-color: #dd4b39;
  margin-right: 10px;
  /* margin-bottom: 15px; */
}

button.button-google:disabled {
  background-color: #e78174;
}

button.button-twitter {
  color: white !important;
  background-color: #00aced;
  margin-right: 10px;
  margin-bottom: 15px;
}

button.button-twitter:disabled {
  background-color: #899bc1;
}

.nav-user-area {
  text-align: right;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.file-peek {
  width: 120px;
  font-size: 13px;
  color: grey;
}

.nav-user-area .nav-user-photo,
.nav-user-area .nav-user-button {
  float: right;
}
.nav-user-photo {
  margin-top: 3px;
}
.nav-user-photo img {
  display: inline-block;
}
.button-navbar.nav-user-button {
  /* margin: 0; */
  /* max-width: 100%; */
}

#mobile-app-header {
  width: 100%;
  text-align: right;
  margin-bottom: 20px;
  cursor: pointer;
}

#mobile-drawer {
  width: 240px;
}
#mobile-drawer .close-drawer {
  position: absolute;
  right: 4px;
  top: 13px;
}
#mobile-drawer .close-drawer button {
  color: white;
  font-size: 25px;
}
#mobile-drawer .nav-user-photo {
  display: inline-block;
}
#mobile-u-name {
  font-size: 15px;
  margin-top: 11px;
  margin-left: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#mobileDrawer button.button-login-other {
  color: white;
}
#mobile-u-email {
  margin: 2px 0 0 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#user-login-mobile {
  background-image: linear-gradient(0deg,#2475d1,#1b4c91 100%,#1f5fae,#13285a);
  padding: 15px;
  color: white;
}
#user-login-mobile .button-login-other {
  color: white;
}
#mobile-drawer a {
  text-decoration: none;
}
#mobile-menu .list {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #6b7c93;
}
#mobile-menu .list-root {
  padding-top: 8px;
  padding-bottom: 8px;
}
#mobile-menu .list-item-root {
  padding: 0 8px;
}
.navbar .button-hamburger {
  line-height: 6px;
  font-size: 9px;
  padding: 8px 6px 8px 24px;
}
.button-hamburger .material-icons {
  font-size: 32px;
}
.sub-menu-mobile {
  margin: 7px 0 -7px 16px;
  color: grey;
  weight: 600;
  font-size: 13px;
}
.img-logo {
  width: 150px;
}
.navbar-fixed .img-logo {
  margin-top: 3px;
}
.navbar-fixed .nav-user-photo {
  margin-top: 8px;
}

button.button-submit {
  margin-top: 15px;
  float: right;
  color: white;
  background-color: #3d7dc8;
}

button[type="button"].button-verify-phone {
  margin-left: 15px;
  margin-top: 10px;
  font-size: 13px;
}

button[type="button"].button-upload-doc, label[role="button"].button-upload-doc {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 4px;
  font-size: 13px;
}

button[type="button"].button-add-more {
  width: 95% !important;
  background-color: white !important;
  color: #3d7dc8 !important;
  margin-top: 20px;
  margin-bottom: 40px;
  border: 1px solid #3d7dc8;
}

button[type="button"].button-upload-doc {
  width: 195px;
}

/* input[type="file"] {
display: none;
} */
div.input-adornment {
  font-size: 13px;
}
div.mou-doc {
  width: 800px;
  max-width: 95%;
  margin: 0;
}

.products-choices {
  margin: 0 auto;
  width: 1100px;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.button-upload-img {
  color: #6b7c93;
  color: rgb(107, 124, 147, 0.88);
  width: 120px;
  text-transform: none;
  padding: 12px 16px;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.5);
  box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.5);
  font-weight: normal;
}

.prop-detail-carou, .prop-detail-stat {
  height: 420px;
}

.blog-card {
  width: 90%;
  min-height: 374px;
  border-radius: 5px;
  box-shadow: 0 6px 8px 0 rgba(164, 164, 164, 0.21);
  background-color: #ffffff;
  margin: 0 auto;
  padding-bottom: 18px;
}

.blog-card-title {
  margin: 15px 15px 10px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.blog-card-content {
  margin: 0 15px !important;
  line-height: 1.5;
  height: 83px;
  overflow: hidden;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
}
.blog-img-hl {
  min-height: 300px;
}


.blog-image {
  width: 100%;
}

.prop-detail-rights {
  /* width: 340px; */
}

.prop-detail-progress {
  width: 95%;
}
.blog-title-det {
  font-size: 25px;
  font-weight: 600;
}

.info-offer.prop-detail-type {
  padding: 30px 10px 24px 30px;
}

.prop-detail-page {
  margin-top: 40px;
  margin-bottom: 40px;
}
.card {
  transform: scale(1.0);
  transition: all 200ms ease-out;
}
.card:hover {
  transform: scale(1.05);
  transition: all 200ms ease-out;
  box-shadow: 8px 10px 12px 0 rgba(164, 164, 164, 0.21) !important;
}
.blog-content-header {
  font-family: 'Open Sans', sans-serif;
}

.card-image {
  width: 100%;
  height: 222px;
  border-radius: 13px 13px 0 0;
}

div.mychip {
  border: 1px solid #4a90e2;
}
span.mychip-label {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: #4a90e2;
  padding: 0 25px;
}

.board-list {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.pf-paper {
  padding: 30px;
}

.board-bg-1 {
  width: 100%;
  height: 724px;
  background-image: linear-gradient(to bottom, #f6faff, #ffffff);
}

.behind {
  position: relative;
  z-index: -1;
}

.footer {
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 0px;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.54;
  letter-spacing: normal;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  color: #6b7c93;
}

.footer-content {
  margin: 0 auto;
  width: 100%;
  text-align: justify !important;
}

/* .card-progress{
background-color: rgba(216, 216, 216, 0.27);
width: 100%;
height: 70px;
display: -webkit-flex;
-webkit-align-items: center;
display: flex;
align-items: center;
} */
.card-detail {
  margin: 0 6% 0 6%;
  font-size: 12px;
  text-align: left;
  padding-bottom: 8px;
}

.card-content {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b7c93;
}

.card-content-home{
  font-size: 11px;
  font-weight: 600;
}

.card-title-home{
  margin-top: 10px;
  font-size: 11px;
  /* font-weight: 600; */
}

.card-title-info{
  font-size: 14px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  height: 80px !important;
}

.card-title-product{
  height: 40px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
}

.card-title-content {
  margin-top: 12px;
  font-size: 13px;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b7c93;
}

.card-detail-title {
  margin-top: 20px;
  font-size: 13px;
  font-weight: 500;
}

.prop-invest {
  /* margin-left: 30px; */
}
.propinvest-detail {
  padding: 30px 8%;
  margin-top: 23px;
}
.propinvest-detail-inverse {
  margin: 0 -8%;
}

.card-detail-content {
  margin-top: 10px;
  font-size: 14px;
}

.search-field {
  background-color: white;
}

#link-logo {
  display: block;
  margin-top: 4px;
  margin-left: 4%;
}

.popular-posts {
  margin: 10px 0 0 26px;
}

.loading .card-title-content, .loading .card-content,
.loading .card-info-content, .loading .card-info-image,
.loading .react-sweet-progress-symbol, .loading button[type="button"],
.loading .my-form-input, .loading .my-form-select, .loading .db-highlight,
.loading .faq-panel, .loading .faq-title, .loading .loading-effect, .loading .green, .loading .red, .loading .statistic-number, .loading .blog-card-title, .loading .blog-card-content {
  color: #eee !important;
  /* Fallback for older browsers */
  color: rgba(0, 0, 0, 0.0) !important;
  background-color: #eee !important;
}
.loading img {
  filter: blur(1px);
}
.loading .my-form-label {
  color: rgba(0, 0, 0, 0.0) !important;
}

.green {
  color: green;
}
.red {
  color: red;
}
.hilite {
  /* for searching*/
  background-color: yellow;
}

#button-dashprof-wrapper {
  text-align: center;
  margin-top: 45px;
}

.img-404-error {
  width: 600px;
  max-width: 90%;
  margin: auto;
}

/* .board-list{
display: flex;
justify-content: center;
} */
/* FOR FOOTER */
/* .wave {
position: relative;
height: 70px;
width: 100%;
background: white;
}

.wave:after {
content: "";
display: block;
position: absolute;
border-radius: 50% 100%;
width: 60%;
height: 68px;
background-color: white;
right: 0px;
top: 27px;
}

.wave:before {
content: "";
display: block;
position: absolute;
border-radius: 50% 100%;
width: 48.8%;
height: 65px;
background-color: #1f5fb0;
left: 0px;
top: 50px;
} */
.sticky {
  position: sticky;
  top: 0;
  transition: .32s;
}

.fixed {
  font-size: 24px;
  position: fixed;
  height: 20px;
  transition: .25s;
}

.top {
  text-align: center;
  background: #2980b9;
  color: #fff;
}

/* .bottom {
padding: 1em 0;
background: transparent;
color: white;
text-align: center;
} */
.fa-login {
  font-size: 15px;
  text-align: center;
  text-decoration: none;
  color: white;
  padding: 4px 0;
}

.fa-login:hover {
  opacity: 0.7;
}

.button-facebook {
  background-color: #3B5998
}

.fa-google {
  background-color: #dd4b39
}

.fa-socmed {
  font-size: 15px;
  width: 32px;
  height: 32px;
  text-align: center;
  text-decoration: none;
  margin: 5px;
  border-radius: 50%;
  line-height: 32px;
  color: white;
  background-color: #3d7dc8;
  border: none;
  cursor: pointer;
}

.dana-untuk-properti {
  font-size: 25px;
  letter-spacing: 0.8px;
  color: #3d7dc8;
}

.in-just-a-few-easy-s {
  margin-top: 20px;
  font-size: 22px;
  letter-spacing: 0.7px;
  color: #32325d;
}

.payment-confirm-subtitle {
  margin-top: 20px;
  letter-spacing: 0.7px;
}

.fa-socmed:hover {
  opacity: 0.7;
  box-shadow: 0 1px 2px 0;
}

.socmed-bottom {
}

.need-fund {
  width: 100%;
  min-height: 220px;
  display: -webkit-flex;
  /* Safari */
  -webkit-align-items: center;
  /* Safari 7.0+ */
  display: flex;
  align-items: center;
}

.need-fund-content {
  margin: 0 auto;
  width: 1100px;
}

.right-fund {
  margin-top: 50px;
  text-align: center;
}

button.call-us {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.5);
  background-color: #3d7dc8;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 30px;
}

.call-us:hover {
  opacity: 0.8;
}

button.login-call {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.5);
  background-color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  color: #3d7dc8;
  margin-left: 15px;
  font-size: 14px;
  letter-spacing: 0.3px;
  margin-top: 30px;
}

/* ABOUT US */
.about-us {
  padding-bottom: 30px;
  font-family: 'Montserrat', sans-serif;
}

.about-title {
  font-size: 24px;
  letter-spacing: normal;
  color: #6b7c93;
}

.right-about>h1 {
  font-size: 24px;
  letter-spacing: normal;
  color: #6b7c93;
  margin-top: 30px;
}

.right-about>p {
  opacity: 0.9;
  font-size: 13px;
  line-height: 2;
  letter-spacing: normal;
  color: #6b7c93;
}

.about-1 {
  min-height: 380px;
  font-size: 13px !important;
}

.about-2 {
  opacity: 0.9;
  font-size: 13px;
  line-height: 2;
  letter-spacing: normal;
  color: #6b7c93;
  padding-top: 30px;
  padding-bottom: 0px;
}

/* founders image */
.about-2 img {
  width: 45%;
  max-width: 135px;
  /* width: 47%;
  max-width: 152px; */
}
.about-2-desc {
  padding-right: 2%;
}

.about-3{

}

.about-section{
  background: linear-gradient(to top, #ffffff, #f1f8ff);
  /* height: 2442px; */
  height: 2227px;
}

.advisor-card{
  position: 'absolute';
  text-align: center;
}
.divider-advisor {
  margin-top: 60px;
}

.advisor-space-2{
  max-width: 920px;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 130px;
}

button.button-advisor{
  font-family: 'Montserrat', sans-serif;
  height: 26px;
  font-size: 13px;
  margin: 0 auto;
  font-style: bold;
  color: #3d7dc8 !important;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  border: 1px solid #3d7dc8;
  margin-top: 15px;
}

.advisor-name{
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.1;
  letter-spacing: 0.4px;
  color: #6b7c93;
}

.advisor-title{
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: 0.4px;
  color: #6b7c93;
  margin-bottom: 20px;
}

.about3-title{
  margin-top: -50px;
  font-size: 19px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: #3d7dc8;
}

.about3-content{
  max-width: 416px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: 0.4px;
  text-align: center;
  color: #6b7c93;
  margin: 0 auto;
}

.team-image{
  width: 65px !important;
}

.about-card-bg{
  height: 354px;
  margin: 0 auto;
  border-radius: 69px;
  background-blend-mode: multiply;
  background-image: linear-gradient(to top, #ffffff, #f1f8ff);
}

.team-card{
  width: 290px;
  margin: 0 12px;
  height: 91px;
  border-radius: 80px;
  box-shadow: 13px 13px 37px -20px rgba(164, 164, 164, 0.21);
  background-color: #ffffff;
  opacity: 0.5;
}

.about4-content{
  max-width: 419.5px;
  margin: 0 auto;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.4px;
  text-align: center;
  color: #6b7c93;
  margin-bottom: 20px;
}

.slick-slide.slick-center .team-card {
  transform: scale(1.1);
  opacity: 1;
  filter: none;
}


.about-card-content{
}

.about-partner{
  padding-top: 250px;
  padding-bottom: 50px;
}

.partner-kami{
  width: 151px;
  margin: 0 auto;
  font-family: Montserrat;
  font-size: 19px;
  font-weight: 600;
  line-height: 1.74;
  letter-spacing: 1.7px;
  color: #3d7dc8;
}

.partner-content{
  width: 360px;
  margin: 0 auto;
  font-family: Montserrat;
  font-size: 13px;
  line-height: 2;
  letter-spacing: 0.4px;
  text-align: center;
  color: #6b7c93;
  margin-bottom: 50px;
}

.del-dialog {
  width: 325px;
  max-width: 95%;
  margin: 0;
}


/*------OJK-----*/

.ojk-card {
  max-width: 1100px;
  min-height: 170px;
  border-radius: 3px;
  box-shadow: 0 6px 8px 0 rgba(164, 164, 164, 0.21);
  background-color: #ffffff;
  margin: 0 auto;
  padding-top: 20px;
  margin-top: 30px;
}

.ojk-left {
  text-align: right;
  margin-right: 20px;
}

.ojk{
  padding-top: 40px;
  padding-bottom: 40px;
}

.ojk-title {
  font-size: 25px;
  line-height: 2.72;
  letter-spacing: normal;
  color: #6b7c93;
  margin: 0 auto
}

.ojk-content {
  height: 60px;
  font-size: 14px;
  line-height: 1.7;
  color: #6b7c93;
  margin: 0 auto;
  text-align: justify;
}

/* .about-us {
width: 1100px;
margin: 0 auto;
} */
.team-image {
  width: 300px;
  height: 180px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.team-image:hover {
  transform: scale(1.05);
  transition: all 220ms ease-in;
  cursor: pointer;
}

/* END ABOUT US */
.info-offer {
  text-align: left;
  display: -webkit-flex;
  /* Safari */
  -webkit-align-items: center;
  /* Safari 7.0+ */
  display: flex;
  align-items: center;
  font-size: 13px;
  letter-spacing: normal;
  color: #6b7c93;
  padding-bottom: 5px;
  padding-left: 23px;
  padding-right: 5px;
}

.info-offer-detail{
  position: absolute;
  letter-spacing: normal;
  color: white;
  margin-top: 132px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 23px;
  padding-right: 5px;
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, rgba(70, 70, 70, 0),	rgba(70, 70, 70, 1));
  width: 92%;
  height: 38px;
}

.how-title {
  font-size: 24px;
  line-height: normal;
  letter-spacing: normal;
  color: #6b7c93;
}


.our-product {
  max-width: 700px;
  margin: 0 auto;
}

.how-width {
  width: 100%;
  margin: 0 auto;
}

.how1{
}

.how1-grid {
  margin: 50px 0 50px;
}

.how1-image {
  width: 100%;
  max-width: 406px;
  display: block;
  margin: auto;
}

.how-page {
  margin: 0 auto;
}

.how-content {
  width: 100%;
  opacity: 0.9;
  font-size: 14px;
  line-height: 2;
  letter-spacing: normal;
  color: #6b7c93;
}

.how-2{
}

.how2-left {
  width: 100%;
}

.how2-content{
  max-width: 409px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: 0.4px;
  text-align: center;
  color: #6b7c93;
  padding-bottom: 60px;
  margin: 0 auto;
}

.how2-right {
  margin: 0 auto;
  height: 100%;
  display: -webkit-flex;
  /* Safari */
  -webkit-align-items: center;
  /* Safari 7.0+ */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.how2-product{
  width: 285px;
  margin: 0 auto;
  font-size: 12px;
  line-height: 2;
  padding-top: 30px;
}

.how3 {
  width: 100%;
  margin: 0 auto;
  padding: 50px 20px;
}
.how3-image {
  width: 90%;
  max-width: 350px;
  margin: auto;
  display: block;
}

.skema-title{
  width: 185px;
  font-family: 'Montserrat', sans-serif;
  font-size: 19px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 1.7px;
  color: #3d7dc8;
}

.skema-content{
  width: 443px;
  max-width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.4px;
  color: #6b7c93;
}

.pro-title {
  width: 88%;
  height: 31px;
  opacity: 0.9;
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: normal;
  color: #454545;
  margin: 0 auto;
  margin-top: 20px;
}

.pro-detail {
  width: 88%;
  opacity: 0.9;
  font-size: 14px;
  font-weight: 400;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #454545;
  margin: 0 auto;
}

.how-image {
  min-width: 300px;
  max-width: 400px;
  margin: 0 auto;
}

.how4{
  padding-top: 80px;
}

.how4-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 19px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.74;
  letter-spacing: 1.7px;
  color: #3d7dc8;
  margin: 0 auto;
  padding-bottom: 40px;
}


.how4-number-1{
  font-size: 72px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.39;
  letter-spacing: normal;
  color: #3d7dc8;
  padding-top: 30px;
}

.how4-number-2{
  font-size: 72px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.39;
  letter-spacing: normal;
  color: #d2ecf9;
  padding-top: 30px;
}

.how4-left-title{
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #6b7c93;
  padding-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

.how4-left-content{
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.92;
  letter-spacing: normal;
  color: #6b7c93;
  max-width: 420px;
  margin-left: 10px;
  margin-right: 10px;
}

.how5{
  padding-top: 90px;
}

.how6{
  padding-top: 100px;
}

.masih-ada-pertanyaan{
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: 0px;
  color: #6b7c93;
}

.how-image {
  width: 450px;
}

.dummy {
  width: 100%;
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: 0.4px;
  text-align: center;
  color: #6b7c93;
}

.ta-center {
  text-align: center !important;
}

/* CENTER IMG */
.img-center {
  display: -webkit-flex;
  /* Safari */
  -webkit-align-items: center;
  -webkit-justify-content: center;
  /* Safari 7.0+ */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.img-home-3{
  width: 250px;
  object-fit: contain;
}

.img-width {
  width: 88%;
  max-width: 489px;
}

.img-about-2{
  width: 139px;
  height: 302px;
}

.card {
  width: 350px;
  margin: 0 auto 40px auto;
  /* margin-bottom: 30px; */
  box-shadow: 0 6px 8px 0 rgba(164, 164, 164, 0.21) !important;
  min-height: 420px;
  padding-bottom: 10px;
  overflow: visible !important;
}
.card.blog-card {
  min-height: auto;
}

.proplist-container {
  /* width: 88%;
  padding-left: 5%;
  padding-right: 5%; */
  /* box-shadow: 0 6px 8px 0 rgba(164, 164, 164, 0.21) !important;
  min-height: 550px; */
}
.home5-container .proplist-container {
  margin: 0 15px;
}

.home1-container .slick-list {
  max-height: 425px;
}

.img-list-product {
  padding-top: 50px;
}

.ojk-image {
  width: 475px;
  height: 321px;
  margin: 0 auto;
}

.ojk-right{
  padding-top: 70px;
}

.card-progress {
  background-color: rgba(216, 216, 216, 0.27);
  text-align: center;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: -10px;
}

.time-remaining{
  width: 100%;
  text-align: left !important;
}

#listing-bar {
  margin-bottom: 32px;
  padding: 10px 20px;
}

.progress-width {
  width: 100%;
}

/*------------*/

/* FOR HOME */

/* FOR HOME */
.home1-grid {
  margin-top: 33px;
}

.home1-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.21;
  letter-spacing: 0px;
}

button.button-mulai-investasi{
  border: 1px solid #1d5d91;
  color: #1d5d91;
  background: transparent;
  font-weight: 400 !important;
  width: 95%;
  margin: 0 auto;
}

button.button-mulai-investasi:hover{
  border: 1px solid #1d5d91;
  color: white;
  background: #1d5d91;
  font-weight: 400 !important;
}

button.btn-dark-blue{
  border-radius:4px;
  background-color: #1d5d91;
  border: 1px solid #1d5d91;
  color: white;
  font-weight: 400 !important;
  width: 95%;
  margin: 0 auto;
}

button.button-lihat-proyek{
  border-radius:4px;
  background-color: #1d5d91;
  border: 1px solid #1d5d91;
  color: white;
  width: 200px;
  font-weight: 400 !important;
  margin: 0 auto;
}

button.button-lihat-proyek:hover{
  border: 1px solid #1d5d91;
  color: #1d5d91;
  background: transparent;
  font-weight: 400 !important;
}

button.btn-dark-blue:hover{
  border-radius:4px;
  background-color: transparent;
  border: 1px solid #1d5d91;
  color: #1d5d91;
  font-weight: 400 !important;
}

.home1-right {
  margin: 0 auto;
  height: 100%;
  display: -webkit-flex;
  /* Safari */
  -webkit-align-items: center;
  /* Safari 7.0+ */
  display: flex;
  align-items: center;
}

.home1-content {
  font-size: 14px;
  padding: 15px 0 18px;
  line-height: 2;
  letter-spacing: 0.3px;
  text-align: justify;
  color: #6b7c93;
}

.home1-image {
  width: 550px;
}
.home-2 {
  position: relative;
  height: 0;
}
.home-2-float {
  position: absolute;
  left: 0;
  right: 0;
  top: -157px;
  text-align: center;
}
.home2-padding {
  height: 230px;
}
.home2-card {
  width: 178px;
  padding: 53px 35px 46px;
  border-radius: 3px;
  min-height: 223px;
  box-shadow: 13px 13px 37px -20px rgba(164, 164, 164, 0.21);
  background-color: #ffffff;
  margin: 0 auto;
  transition: box-shadow 200ms ease-out;
}
.home2-card:hover{
  box-shadow: 20px 20px 44px -13px rgba(164, 164, 164, 0.21);
  transition: box-shadow 200ms ease-out;
}
.home2-card-content{
  line-height: 1.86;
  letter-spacing: 0.4px;
}

.for-flipper{
  padding-top: 60px;
  padding-bottom: 40px;
}

.img-card-home{
  width: 100%;
  text-align: center;
}

.data-statistic{
  width: 100%;
  text-align: center;
}
.stat-container {
  padding: 30px 0 50px;
}
.stat-statement {
  text-align: center;
  font-size: 14px;
  margin: 35px auto 0;
  width: 600px;
  max-width: 100%;
}

.stat-icon {
  width: 75px;
  margin: auto;
  display: block;
  margin-bottom: 15px;
}

.statistic-number{
  font-family: 'Work Sans', sans-serif;
  font-size: 43px;
  font-weight: 600;
  color: #3d7dc8;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.00;
  letter-spacing: normal;
}

.statistic-title{
  margin-top: 0 !important;
  font-size: 14px;
  color: #3d7dc8;
  padding-top: 15px;
}

.home3{
  width: 100%;
  margin: 0 auto;
  padding-bottom: 30px;
  min-height: 500px;
}

.home5-container{
  padding-top:35px;
}

.home1-container{
  margin: 0 15px;
}

.home3-title{
  /* padding-top: 250px; */
}

.home3-bg{
  background-image: url("./assets/home/bg-cara.svg");
  background-size: auto;
  background-repeat: repeat-x;
  min-height: 500px;
  margin-top: 30px;
}

.home3-content-container{
  padding-left: 6%;
}

.home3-content{
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.85;
  letter-spacing: 0.3px;
  color: #6b7c93;
  text-align: center;
  margin: 7px -20px auto;
}

.how-to-invest{
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #6b7c93;
}

.investy-content{
  font-size: 14px;
  line-height: 2;
  letter-spacing: 0.3px;
  color: #3d7dc8;
}

.home-title {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.3px;
  color: #3d7dc8;
}

.home-content {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: 0.3px;
  text-align: justify;
  color: #6b7c93;
}

.flipper-right{
  width: 95%;
  display: flex;
  justify-content: flex-end;
  padding-top: 50px;
}

.flipper-title{
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0px;
  color: #6b7c93;
}


button.cara-pinjaman{
  margin-top: 40px;
  width: 250px;
  border-radius: 4px;
  color: #1d5d91;
  border: 1px solid #1d5d91;
}

.faq-section {
  width: 100%;
  margin-bottom: 28px;
  clear: both;
}

.faq-title {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 34px;
}

.faq-q {
  font-weight: 600;
  margin: 10px 37px 10px;
}

.faq-ans {
  margin: 5px;
  line-height: 2;
  text-align: justify !important;
}
.faq-ans > p{
  margin: 0;
}


.btn-learn-more {
  background-color: transparent;
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #3d7dc8;
  margin-left: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sk-spinner.my-spinner {
  margin: 50px auto;
  color: #1d5d91
}


.faq-item .faq-panel {
  margin-bottom: 20px;
  -webkit-box-shadow: 0 6px 8px 0 rgba(164, 164, 164, 0.21);
  box-shadow: 0 6px 8px 0 rgba(164, 164, 164, 0.21);
}

.faq-panel.faq-panel-expanded {
  margin-bottom: 44px !important;
}

/*----DETAIL-INFO----*/
.detail-accor {
  display: flex;
  justify-content: center;
  padding-top: 2em;
  padding-bottom: 3em;
}

.accor-title {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
  color: #6b7c93;
}

.accor-content {
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.3px;
  color: #6b7c93;
}

.html-content {
  margin: 0;
}

.html-content p {
  margin: 0;
}

/*----END-DETAIL-INFO----*/
/*END OF HOME*/
/*START OF LOAN*/
.loan-header {
  background-image: linear-gradient(1deg, #ffffff, #f1f8ff);
}

.loan-1 {
}

.loan1-left{
}
.loan1-image {
  width: 80%;
  max-width: 500px;
  display: block;
  margin: auto;
}
.loan1-right {
}
.loan1-grid {
  margin-top: 60px;
}
.loan1-buttons {
  margin-top: 30px;
}

button.loan1-button{

}

.loan-2 {
  padding-top: 80px;
  padding-bottom: 0px;
  text-align: center;
}
.img-loan4 {
  padding: 120px 0 60px;
}

.bg-build{
  background-image: url('assets/loan/bg_building.svg');
  background-size: auto;
  background-repeat: repeat-x;
}

.loan2-title{
  font-family: 'Montserrat', sans-serif;
  font-size: 19px;
  margin: 0 auto;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.74;
  letter-spacing: 1.7px;
  text-align: center;
  color: #3d7dc8;
}

.loan-title-2{
  object-fit: contain;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-align: center;
  color: #6b7c93;
}

.loan2-content{
  width: 296px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: 0.4px;
  text-align: center;
  color: #6b7c93;
  padding-bottom: 50px;
  margin: 0 auto;
}

.kemudahan-pengajuan{
  font-size: 26px;
  font-weight: 500;
  line-height: 1.21;
  letter-spacing: 0px;
}

.flipping-property{
  font-size: 24px;
  line-height: 1.42;
  letter-spacing: 0px;
}

.circle{
  height: 91px;
  width: 91px;
  border-radius: 50%;
  box-shadow: 4px 6px 40px -22px rgba(0, 0, 0, 0.37);
  background-color: white;
}

.circle-loan{
  margin-top: 30px;
}

.loan3-card{
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 20px;
}

.flipping-title{
  font-size: 19px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.74;
  letter-spacing: 1.7px;
  color: #3d7dc8;
}

.loan3-title{
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #6b7c93;
}

.loan3-content{
  font-size: 13px !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.92;
  letter-spacing: normal;
  color: #6b7c93;
}

.loan-title {
  font-size: 19px;
  font-weight: 600;
  color: #5089d3;
}

.loan-content {
  opacity: 0.9;
  font-size: 14px;
  line-height: 2;
  letter-spacing: normal;
  text-align: justify;
  color: #6b7c93;
}

.loan-right>p {
  line-height: 2;
}

.loan1-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.21;
  letter-spacing: 0px;
}

.loan1-subtitle{
  font-size: 24px;
  line-height: 1.42;
  letter-spacing: 0px;
}

.loan1-content{
  line-height: 2;
  letter-spacing: 0.3px;
  padding: 17px 0 5px;
}

.list-terms {
  list-style: none;
}

.list-terms li::before {
  content: "\2022";
  color: red;
  font-weight: 900;
  display: inline-block;
  width: 2em;
  margin-left: -2em;
}

.list-terms>li {
  text-align: justify;
  letter-spacing: 0.4px;
  line-height: 1.86;
}

.img-update-timeline {
  display: block;
  /* width: 60px;
  height: 60px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -30px;
  margin-top: -30px; */
}

.img-circle {
  border-radius: 50%;
}

.loan5-title{
  font-size: 19px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.74;
  letter-spacing: 1.7px;
  text-align: center;
  color: #3d7dc8;
  padding-top: 50px;
}

.loan5{
  padding-bottom: 70px;
}


.container-loan5 {
  margin-top: 30px;
  position: relative;
  width: 228px;
  height: 230px;
}


.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #D2ECF9;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}

.container-loan5:hover .overlay {
  height: 230px;
}

.loan5-card-title {
  color: #3D7DC8;
  font-size: 14px;
  position: absolute;
  top: 50px;
  left: 50%;
  width: 200px;
  font-weight: 600;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.text-loan5 {
  color: #3D7DC8;
  font-size: 14px;
  position: absolute;
  top: 120px;
  left: 50%;
  width: 200px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.kamu-sudah-tahu {
  max-width: 435px;
  padding-top: 30px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: 0.4px;
  text-align: center;
  color: #6b7c93;
  margin: 0 auto
}


.loan5-card{
  width: 228px;
  height: 230px;
  border-radius: 3px;
  box-shadow: 13px 13px 37px -20px rgba(164, 164, 164, 0.21);
  background-color: #ffffff;
  margin: 0 auto;
  display: block;
  width: 100%;
}

.loan5-card-content{
  padding-top: 50px;
}


.loan6-title{
  max-width: 411px;
  font-size: 19px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.74;
  letter-spacing: 1.7px;
  margin: 0 auto;
  color: #3d7dc8;
}

.loan6-content{
  max-width: 411px;
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: 0.4px;
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
  color: #6b7c93;
}

/*For Modal*/
.modal-title {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-align: left !important;
  color: #6b7c93;
  margin-left: 25px;
}

.modal-home-content {
  background-color: #0075DF;
  background-image: url("./assets/pop-up/building.png");
  background-size: 550px 90px;
  background-position: center bottom;
  background-repeat:repeat-x;
  min-width: 550px;
  height:400px;
  padding: 15px;
}

.mg-up30{
  margin-top: -30px;
}

.modal-prohouse-content {
  background-color: #0075DF;
  background-image: url("./assets/pop-up/prohouse_bg-min.png");
  background-size: 550px 300px;
  background-position: center bottom;
  background-repeat:repeat-x;
  min-width: 550px;
  height:400px;
  padding: 15px;
}

.rodal-special {
  display: flex;
  align-items: center;
}

/*--------*/
/*END OF LOAN*/
.login-page {
  width: 100%;
  height: 100vh;
  background:  url('assets/bg-auth.png') left bottom no-repeat, linear-gradient(to bottom, #f1f8ff, #ffffff);
}

div.timeline-content p {
  margin-top: 0px;
  font-size: 14px !important;
  font-weight: normal;
  color: #6b7c93;
}

div.vertical-timeline.vertical-timeline--two-columns {
  width: 100%;
  margin-top: 0;
}

.timeline-content img {
  max-width: 100%;
}

/*----review PART----*/
.review-detail {
  margin-top: 30px;
}

.instructions-card {
  width: 90%;
  max-width: 500px;
  min-height: 42px;
  border: 1px solid black;
}

/*END OF review*/
/*----SPECIAL WIDTH----*/
.width-90 {
  padding-left: 10%;
  padding-right: 10%;
}

/*----END OF SPECIAL WIDTH----


/* SPECIAL BACKGROUND */
.bg-lightblue {
  background-color: #f6f9fc !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-darkblue {
  background-image: linear-gradient(to top, #2475d1, #1b4c91 100%, #1f5fae, #13285a);
}
/* END SPECIAL BACKGROUND */
/* AUTH STYLES */
.auth-box {
  position: relative;
  z-index: 100;
  max-width: 342px;
  background-color: white;
  box-shadow: 4px 7px 37px 0 rgba(224, 224, 224, 0.46);
}

.faux-tab {
  height: 48px;
  background: white;
  font-size: 14px;
  color: #3d7dc8;
  font-weight: 500;
  box-shadow: 0 0 13px 0 #f0f0f0;
  margin-bottom: 5px;
}

.auth-box .tab-container {
  padding: 25px 20px;
}
.auth-box .my-form-input-outlined {
  background-color: #f6f6f6;
}
.auth-box .my-form-input-outlined.input-focused {
  background-color: #fcfcfd;
}

.auth-form {
  /* padding: 0 13px 8px 13px; */
}

.input-width {
  width: 100%;
}

.login-title {
  font-size: 20px;
  letter-spacing: normal;
  color: #6b7c93;
}

.font-control {
  font-size: 14px;
  color: #6b7c93;
}

.or-sign-in {
  font-size: 12px;
  letter-spacing: normal;
  color: #6b7c93;
  text-align: center !important;
}

.with-auth{
  margin-top: 5px;
  font-size: 13px;
  color: #787878;
}

.sign-up-now {
  position: relative;
  z-index: 100;
  max-width: 100%;
  background-color: transparent;
  font-size: 13px;
  letter-spacing: normal;
  color: #6b7c93;
  padding: 5px 0px;
  text-align: center;
}

.sign-up-button {
  color: #6b7c93;
  font-weight: bold;
}

.sign-up-button:hover {
  opacity: 0.7;
  cursor: pointer;
}

/* END OF AUTH STYLES */
/* START OF DETAIL PRODUCT */
/* END OF DETAIL PRODUCT */
/* SPECIAL POSITION */
.center-content {
  height: 100%;
  display: -webkit-flex;
  /* Safari */
  -webkit-align-items: center;
  /* Safari 7.0+ */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}



.center-mode {
  display: -webkit-flex;
  /* Safari */
  -webkit-align-items: center;
  /* Safari 7.0+ */
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-mode {
  height: 100%;
  display: -webkit-flex;
  /* Safari */
  -webkit-align-items: center;
  /* Safari 7.0+ */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.right-mode > div {
  margin-top: auto;
}


/* END OF SPECIAL POSITION */
.slick-prev:before, .slick-next:before {
  color: black !important;
}

.slick-prev:hover, .slick-next:hover {
  cursor: pointer;
}



/* page transition  */
.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-out;
}

.fade10000-enter {
  opacity: 0.01;
}
.fade10000-enter.fade10000-enter-active {
  opacity: 1;
  transition: opacity 10000ms ease-out;
}
.fade10000-exit {
  opacity: 1;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.fade10000-exit.fade10000-exit-active {
  opacity: 0.01;
  transition: opacity 10000ms ease-out;
}

.fade500-enter {
  opacity: 0.01;
}
.fade500-enter.fade500-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-out;
}
.fade500-exit {
  opacity: 1;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.fade500-exit.fade500-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-out;
}

#preloader-wrapper {
  opacity: 0;
  transition: opacity 500ms ease-out;
}
#preloader-wrapper > #pre-circle {
  width: 280px;
  transition: width 500ms ease-out;
}
#preloader-wrapper > #pre-logo {
  width: 100px;
  transition: width 500ms ease-out;
}

div.transition-group {
  position: relative;
}

section.route-section, .wrapper-anim {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.image-wrapper {
  /* must be overridden by each wrapper,
  padding-bottom: formula  round(100 * $o_height / $o_width, 2)% */
  padding-bottom: 50%;
  width: 100%;
  position: relative;
}

.image-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.pagination>li {
  display: inline;
}

.pagination>li :focus {
  outline: none;
}

.pagination-container .pagination>li.disabled:first-of-type>a,
.pagination-container .pagination>li.disabled:last-of-type>a {
  border-color: #DEDFE0;
  cursor: default;
}

.pagination>li>a {
  border-radius: 50%;
  display: inline-block;
  float: none;
  height: 32px;
  line-height: 32px;
  margin: 0 1px;
  padding: 0;
  vertical-align: middle;
  width: 32px;
  cursor: pointer;
}

.full-width {
  width: 100% !important;
}

.pagination-container {
  overflow: auto;
  padding: 16px 0;
  text-align: center;
  white-space: nowrap;
}

.pagination-container .pagination {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43em;
  margin: 0;
}

.pagination-container .pagination>li>a {
  border-radius: 50%;
  display: inline-block;
  float: none;
  height: 32px;
  line-height: 32px;
  margin: 0 1px;
  padding: 0;
  vertical-align: middle;
  width: 32px;
}

.pagination-container .pagination>li:first-of-type>a {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
  border: 1px solid #3d7dc9;
  border-radius: 50%;
  height: 48px;
  line-height: 47px;
  width: 48px;
  margin: 0 4px 0 0;
}

.pagination-container .pagination>li:last-of-type>a {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
  border: 1px solid #3d7dc9;
  border-radius: 50%;
  height: 48px;
  line-height: 47px;
  width: 48px;
  margin: 0 0 0 4px;
}

.pagination>.active>a, .pagination>.active>span, .pagination>.active>a:hover, .pagination>.active>span:hover, .pagination>.active>a:focus, .pagination>.active>span:focus {
  z-index: 3;
  color: #FFF;
  background-color: #3d7dc9;
  border-color: #3d7dc9;
  cursor: default;
}

.pagination>.active>a, .pagination>.active>span, .pagination>.active>a:hover, .pagination>.active>span:hover, .pagination>.active>a:focus, .pagination>.active>span:focus {
  z-index: 3;
  color: #FFF;
  background-color: #3d7dc9;
  border-color: #3d7dc9;
  cursor: default;
}

.search-not-found {
  font-family: 'Montserrat', sans-serif;
  color: #6b7c93;
  margin: 36px 0 55px 0;
  text-align: center;
  font-size: 16px;
  min-height: 160px;
}

.paper-title {
  padding: 13px 48px;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 3px solid #e6e6e6;
}
.paper-body {
  padding: 16px 48px 20px;
}
.paper-body-inverse {
  margin: 0 -48px;
}
.paper-body-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 14px;
}
.pro_code {
  font-size: 11px;
  font-weight: 300;
}
.pro_code.bigger {
  font-size: 12px;
}
.paper-value {
  font-size: 16px;
  font-weight: bold;
  color: #24b47e;
}
.payment-bank {
  padding: 12px 30px;
}
.faq-loading-animation {
  margin-top: 80px;
}

/*FOR ANIMATION*/
.scale-in-ver-top {
  -webkit-animation: scale-in-ver-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-in-ver-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
div.spacing-xs {
  width: 100%;
  margin: 0;
}

.sms-modal .rodal-dialog {
  background-color: #f6f9fc;
}
.sms-modal.payment-modal .rodal-dialog {
  padding: 0;
}


.sms-modal-content p {
  text-align: left;
}

.sms-modal-content {
  padding: 10px 30px;
}

.sms-modal-title {
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
}

.sms-modal-buttons {
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: left;
}

.sms-modal-sendagain {
  font-size: 13px;
}

.sms-modal-buttons button {
  -webkit-box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.5);
  box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.5);
}

.form-content .my-form-input-outlined input {
  background-color: white;
  padding: 10px 10px;
}
.form-content .my-form-input-outlined input:disabled {
  background-color: #ddd;
}
.form-content .my-form-label.label-outlined:not(.label-shrink) {
  transform: translate(14px, 12px) scale(1);
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  padding-top: 10px;
}
.sms-modal .rodal-dialog {
  background-color: #f6f9fc;
  max-width: 93%;
}

.form-content-info {
  padding-top: 10px;
}

a.casual-link {
  text-decoration: none;
  color: #3d7dc8;
}

ul.list-document {
  text-align: left;
  margin-top: 0px;
  padding-left: 20px;
}

ul.list-document li {
  list-style-type: none;
}

.button-progress-wrapper {
  position: relative;
  display: inline-block;
}

.button-progress-wrapper-full {
  position: relative;
}
.img-popular-post {
  display: block;
  width: 80px;
  height: 80px;
}

.button-progress {
  color: green;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

/* END OF AUTH STYLES */
@-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

.bounce-bottom {
  position: relative;
  top: 270px;
}

.nav-home .home1 .bounce-bottom {
  position: absolute;
  bottom: 8em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  width: 30px;
  height: 50px;
  -webkit-animation: bounce 2.0s infinite;
  /* Safari 4.0 - 8.0 */
  -moz-animation: bounce 2.0s infinite;
  /* Mozilla */
  -o-animation: bounce 2.0s infinite;
  /* Opera */
  animation: bounce 2.0s infinite;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes bounce {
  from {
    top: 88%;
  }

  to {
    top: 95%;
  }
}

@-o-keyframes bounce {
  from {
    top: 88%;
  }

  to {
    top: 95%;
  }
}

@-moz-keyframes bounce {
  from {
    top: 88%;
  }

  to {
    top: 95%;
  }
}

@keyframes bounce {
  from {
    top: 88%;
  }

  to {
    top: 95%;
  }
}

.shadow-box {
  -webkit-box-shadow: 0 6px 8px 0 rgba(164, 164, 164, 0.21);
  box-shadow: 0 6px 8px 0 rgba(164, 164, 164, 0.21);
}

.cred-area {
  margin-top: 40px;
  padding: 40px;
}

.img-carousel {
  height: 100;
  width: 300
}

.img-thumbnail {
  height: 10;
  width: 30
}

.img-big {
  width: 92%;
  margin: 0 auto
}

.img-circle {
  border-radius: 50%;
}

.image-gallery-content {
  width: 90%;
  margin: auto;
  text-align: center;
}
.right-blog-divider {
  border-right: 1px solid #d8d8d8;
  padding: 1px !important;
  height: 40px;
}


.slide-down {
  max-height: 0;
  opacity: 0;
}

.slide-down.open {
  max-height: 500px;
  opacity: 1;
  transition: all 1s;
}

.slide-up {
  /* max-height: 800px; */
  opacity: 1;
}

.slide-up.close {
  max-height: 0;
  opacity: 0;
  transition: all 1s;
}

.htl-container {
  padding-top: 70px;
  padding-bottom: 70px;
  text-align: center;
}
.hidden {
  display: none;
}

/* .image-gallery-image img {
height: 273px;
} */
.stepper-label {
  padding: 20px;
  width: 468px;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
}
.stepper-loan.stepper-label {
  width: 650px;
}

.stepper-content {
  padding: 10px;
  width: 650px;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  /* text-align: center; */
}

.container {
  margin: 0 auto;
}

.c-white {
  color: white !important;
}

.bottom-login {
  margin-left: 35px !important;
}
div.stepper-label {
  padding: 20px 0px;
  width: 468px;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.apply-loan-buttons {
  margin-top: 100px;
}

.my-spinner-container {
  height: 190px;
  padding-top: 40px;
  text-align: center;
}

.my-spinner-container > div {
  color: #1d5d91;
  margin: auto;
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 30px;
}

td.transact-cell > div {
  white-space: nowrap;
}

/*-----risk content------*/
.risk-top {
  line-height: 2;
  text-align: justify;
}
button.btn-stepper-next {
  width: 170px;
  box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.5);
}
.prop-detail p {
  margin: 0;
}
.prop-detail p.accor-title {
  margin: inherit;
}
.prop-detail .table td, .prop-detail .table th {
  padding: 7px;
}

.capital-risk {
  font-size: 35px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  color: #6b7c93;
}

/*------End Of Risk------*/
/*------Career content-----*/
.career1 {
  min-height: 100vh;
}

.career1-left {
  font-size: 14px;
  line-height: 2;
  letter-spacing: 0.3px;
  text-align: justify;
}

.career-top {
  width: 100%;
  background-image: linear-gradient(to top, #ffffff, #f1f8ff) !important;
}

/* xl */
/* @media (min-width:1920px) {
width: 1170px
} */
/* lg & xl */
.center {
  transform: scale(1.05);
}

.slider {

  /* max-width: 1024px; */
  margin: 0 auto;
  /* border: 1px solid; */
  position: relative;
  &:after, &:before {
    content: "";
    background: #FAFAFA;
    position: absolute;
    top: 0;
    height: 100%;
    width: 12%;
    z-index: 100;
    /* //opacity: .9; */
  }
  &:after {
    right: 0;
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
  }
  &:before {
    background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
  }
}

.faux-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.slick-slide {
  transform: scale(1);
  transition: .3s ease-in-out all;
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.blog-header .slick-slide {
  padding: 0;
}

.slick-slide.slick-center.slick-current.about-card {
  transform: scale(1.02);
  filter: none;
}

div.slick-current{
  transform: scale(1.00);
  filter: none;
}

.blog-hl div.slick-current{
  transform: scale(1);
}


.about-card {
  width: 320px;
  height: 170px;
  border-radius: 3px;
  box-shadow: 13px 13px 37px -20px rgba(164, 164, 164, 0.21);
  background-color: #ffffff;
  filter: blur(2px);
  text-align: justify !important;
  font-size: 14px;
  padding: 25px 15px 25px 15px;
}

.home-card {
  width: 250px;
  min-height: 150px;
  border-radius: 3px;
  box-shadow: 13px 13px 37px -15px rgba(164, 164, 164, 0.21);
  background-color: #ffffff;
  text-align: justify !important;
  opacity: 0.4;
  font-size: 14px;
  padding: 0px 0px 25px;
  margin: 0 auto;
  transform: scale(0.98);
  transition: all .3s ease;
}

.slick-slide.slick-active .home-card {
  transform: scale(1.02);
  transition: all .3s ease;
  opacity: 0.7;
}
.slick-slide.slick-active.slick-current .home-card {
  transform: scale(1.05);
  transition: all .3s ease;
  opacity: 1;
}

.home5-container .home-card .card-info-image {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

button.slick-arrow.slick-prev:before{
  margin-top: 200px !important;
}

.risk-slider-content{
  /* line-height: 2;
  display: flex;
  align-items: center;
  height: 100%;
  text-align: justify; */
}
ul.pagination {
  padding: 0;
}
.blog-hl .risk-slider-content {
  line-height: 1.8;
}

.capital-risk{
  font-size: 35px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  color: #6b7c93;
}

.blog-title-hl {
  font-size: 25px;
  font-weight: 500;
}
.blog-author-hl  {
  margin: 22px 0 20px;
  font-size: 13px;
}
.blog-header .risk-slider-content .html-content {
  max-height: 220px;
}
.pushup {
  margin-top: -90px;
  padding-top: 90px;
}
.pushupx {
  margin-top: -260px;
  padding-top: 260px;
}
.wavy {
  height: 100%;
  width: 100%;
  size: cover;
  background-image: url("./assets/home/wavebackground.svg");
  background-repeat: repeat-x;
}
.wavyflip {
  height: 100%;
  width: 100%;
  size: cover;
  background-image: url("./assets/home/waveflip.svg");
  background-repeat: repeat-x;
}
.flipx {
  transform: scaleX(-1);
  filter: FlipH;
}

.bg-lightgreen {
  height: 100%;
  background-image: linear-gradient(to bottom, #f1f8ff, #ffffff);
}
.bg-lightgreen-static {

  background-color: #f1f8ff;
}

.bg-lightgreen-reverse{
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, #f1f8ff, #ffffff);
}

.slick-arrow {
  display: none !important;
}



/*-----slider switch button---------*/

.abg-switch__state{
  outline: none !important;
  text-decoration: none !important;
  color: gray !important;
}
.abg-switch__state:hover{
  color: #3d7dc8 !important;
}

span.abg-switch__state--on{
  z-index: 10 !important;
  color : white !important;
}
span.abg-switch__state--on:hover{
  color : white !important;
}

.abg-switch__overlay{
  background: #3d7dc8 !important;
  box-shadow: none !important;
  color: white !important;
}

div.abg-switch__container{
  border-color: #e5e8ea;
}

div.abg-switch{
  min-width: 95% !important;
  width: auto;
  margin: auto;
}


/*--------end of slider-----------*/


/*----------oval--------*/
.oval-3 {
  width: 12px;
  height: 12px;
  background-color: #3d7dc8;
  border-radius: 50%;
  margin-top: 20px;
}

/*----------------------*/



/*-------Skema Pinjaman----------*/
.skema-pinjaman{
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.85;
  letter-spacing: 0.3px;
  color: #6b7c93;
}

@keyframes fade-in-left {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.fade-in-left {
  animation: fade-in-left 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@keyframes fade-in-right {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.fade-in-right {
  animation: fade-in-right 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
#auth-root {
  width: 342px;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 120px;
}


.card-store{
  box-shadow: 8px 10px 12px 0 rgba(164, 164, 164, 0.21) !important;
}

.capsule{
  width:141px;
  height: 29px;
  border-radius: 13px;
  text-align: center;
  color: #6b7c93;
  border: 1px solid #6b7c93;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*-------For Prohouse------*/

.prohouse1-height{
  min-height: 384px;
  display: flex;
  align-items: center;
}

.prohouse2-height{
  padding-top: 100px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 30px;
}

.img-prohouse{
  height: 190px;
  object-fit: contain;
}

/*-------------------------*/



/*-------For testimoni----------*/
.testimoni{
  padding-top: 40px;
  padding-bottom: 40px;
}

.testimoni-investor{
  width: 212px;
  margin: 0 auto;
  font-size: 19px;
  font-weight: 600;
  line-height: 1.74;
  letter-spacing: 1.7px;
  text-align: center;
  color: #3d7dc8;
}

.some-testimoni{
  /* width: 312.8px; */
  margin: 0 auto;
  font-size: 13px;
  line-height: 2;
  letter-spacing: 0.4px;
  text-align: center;
  color: #6b7c93;
}
.slick-slide > div > div {
  outline:none;
}

.testimoni-card {
  width: 400px;
  margin: 0 auto;
  min-height: 100px;
  border-radius: 5px;
  box-shadow: 13px 13px 37px -20px rgba(164, 164, 164, 0.21);
  background-color: #ffffff;
  opacity: 0.5;
  margin: 0 20px;
  padding: 15px;
  outline:none;
  transform: scale(1);
  transition: transform 300ms ease-in-out;
}

.slick-slide.slick-center .testimoni-card {
  opacity: 1;
  filter: none;
  transform: scale(1.05);
  transition: transform 300ms ease-in-out;
}

.testimoni-name{
  font-family: Montserrat;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.86;
  letter-spacing: 0.4px;
  color: #6b7c93;
}

.please-login-text {
  line-height: 1.6;
  font-style: italic;
}
.please-login button {
  min-width: 105px;
}

.testimoni-content{
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: #6b7c93;
}
.login-position button {
  min-width: 85px;
}
/*-----------------------------*/

.stat-statement{
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: 0.4px;
  text-align: center;
  color: #6b7c93;
}

.demokratisasi{
  max-width: 396px;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b7c93;
}

div.MuiExpansionPanelDetails-root-419{
  text-align: justify;
}


.plus-div{
  margin-top: 100px;
}

.our-partner{
  padding-top: 200px;
}

.call-us{
  padding-top: 100px;
  line-height: 1.6;
}

.call-us-title{
  width: 186px;
  height: 30px;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #6b7c93;
}

.partner-title{
  text-align: center;
  margin: 0 auto;
  font-size: 19px;
  font-weight: 600;
  line-height: 1.74;
  letter-spacing: 1.7px;
  color: #3d7dc8;
}

.partner-content{
  width: 352px;
  max-width: 100%;
  margin: 0 auto;
  font-size: 13px;
  font-weight: normal;
  line-height: 2;
  letter-spacing: 0.4px;
  color: #6b7c93;
}
.attention-content li {
  margin-top: 10px;
  margin-left: -25px;
}

.tab-risk{
  margin-top: 40px;
}

.risk-content{
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  vertical-align: baseline;
  color: #6b7c93;
  line-height: 2;
}

.prop-detail i {
  font-size: 16px;
}

/* ----------------------------------------------
* Generated by Animista on 2019-3-1 11:48:36
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation fade-in
* ----------------------------------------
*/
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.selector {
  margin: 0;
  padding: 0;
  list-style: none;
}
.selector > li {
  display: inline-block;
  padding: 0 0.5rem;
}
.selector button {
  margin-top: 10px;
  background-color: transparent;
  box-shadow: none;
  border: none;
  outline: none;
  min-width: 10px;
  transition: all 0.5s ease-out;
  cursor: pointer
}
.selector button.active {
  border-bottom: 1px solid blue;
}
blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}

/* div.react-sweet-progress-symbol{
display: none;
} */
/* div.react-sweet-progress-symbol > svg{
display: flex;
} */

.progress-full{
  width: 100%;
  margin-top: 0px;
}

.progress-unfull{
  width: 100% !important;
  margin-top: 5px;
}

.symbol-hide{
  display: none;
}

div.animated .fadeInUp{
  animation-fill-mode: inherit !important;
}

#form-invest .cb-root {
  margin: 2px 0 0;
}
#form-invest .my-radio {
  padding: 10px;;
}
#form-invest .my-radio svg {
  font-size: 24px;
}
.padding11 {
  padding: 11px !important;
}



/*--------------for artboard---------*/

.artboard-1 {
  background-image: url("./assets/home/bg-rumah.png") , linear-gradient(#B4F5FF, #B4F5FF);
  background-size: 100% 100%;
  background-repeat:repeat-x;
  height:100%;
}

.artboard-2 {
  background: #B4F5FF;
  background-size: 100% 100%;
  background-repeat:repeat-x;
  height:100%;
}

.artboard-content{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 100;
  text-align: center;
  max-width: 650px;
}

.artboard-2-bottom {
  background-image: url("./assets/artboard/artboard2bottom.png");
  background-size: contain;
  background-position: bottom center;
  background-repeat:no-repeat;
  height:100%;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.artboard-3-content{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: center;
  max-width: 900px;
}

.artboard-3-bottom {
  background-image: url("./assets/artboard/section3bottom.png");
  background-size: 883.5px 189px;
  background-position: bottom center;
  background-repeat:no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
}


.artboard-4 {
  background-image: url("./assets/artboard/awan_kinton.png") , linear-gradient(#B4F5FF, #B4F5FF);
  background-size: 100% 100%;
  background-repeat:no-repeat;
  height:100%;
  width: 100%;
}

.artboard-4-bottom {
  background-image: url("./assets/artboard/section4bottom.png");
  background-size: 389px 246px;
  background-position: bottom center;
  background-repeat:no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.artboard-5-bottom {
  background-image: url("./assets/artboard/section5building.png");
  background-size: 708px 339px;
  background-position: bottom right;
  background-repeat:no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.section-5-font{
  max-width: 700px;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
}

.artboard-6 {
  background-image: url("./assets/artboard/section6bg.png") , linear-gradient(#B4F5FF, #B4F5FF);
  background-size: 100% 100%;
  background-repeat:repeat-x;
  height:100%;
}

.artboard-home {
  background-image: url("./assets/home/rewind.png");
  background-size: 900px 370px;
  background-repeat:no-repeat;
  background-position: center center;
  height: 370px;
  text-align: center;
}
.artboard-button-width{
  width: 150px;
}
.auto-field-1 {
  width: 162px;
}

/*----------------------------------------------*/


.arrow-down{
  transform: rotate(90deg);
}
.ExpansionPanelSummary-expanded {
  margin-bottom: 0 !important;
}
